import React, { Component } from 'react';
import logoMin from './logoMin.svg';
import logo from './logo.svg';
import nettbil from './nettbil.svg';
import biosort from './biosort.png';
import './App.css';
import "/node_modules/flag-icons/css/flag-icons.min.css";

const App = () => {
  return (
    <div className="App">
      {/* Header Section */}
      <header className="header">
        <img src={logoMin} alt="Flow Lab Logo" className="header-logo" />
        <nav className="nav">
          <a href="#about">About Us</a>
          <a href="#services">Services</a>
          <a href="#customers">Customers</a>
          <a href="#contact">Contact</a>
          <a href="https://blog.flowlab.no">Blog</a>
        </nav>
      </header>

      {/* Hero Section */}
      <section className="hero">
        <img src={logo} alt="Flow Lab" className="main-logo" />
        <p>Innovative Machine Learning and AI Solutions</p>
        <a href="#contact" className="cta-button">Get Started with Flow Lab Today</a>
      </section>

      {/* About Us Section */}
      <section id="about" className="content-section">
        <h2>About Us</h2>
        <p>
          Flow Lab is a Norway-based company specializing in AI and machine learning solutions. We unlock your data’s potential with predictive models like market price prediction and lead scoring to optimize sales and boost revenue. Stay ahead in the Norwegian market with our tailored AI strategies designed to drive growth. Contact us to discover how Flow Lab can fuel your business success in Norway.
        </p>
      </section>

      {/* Services Section */}
      <section id="services" className="content-section">
        <h2>AI & ML Services</h2>
        <div className="services">
          <div className="service">
            <h3>Predictive Modeling</h3>
            <p>We develop advanced predictive models, such as market price prediction, to help you make informed pricing decisions and stay competitive.</p>
          </div>
          <div className="service">
            <h3>Lead Scoring & Sales Optimization</h3>
            <p>Optimize your sales process with lead scoring models that identify high-potential leads, boosting sales and increasing revenue.</p>
          </div>
          <div className="service">
            <h3>AI Consulting</h3>
            <p>We help you leverage AI to solve complex business problems, providing expertise in model development, data analysis, and AI strategy.</p>
          </div>
          <div className="service">
            <h3>Machine Learning Model Development</h3>
            <p>Custom machine learning models tailored to your specific business needs, from predictive analytics to automation.</p>
          </div>
          <div className="service">
            <h3>Flow MLOps</h3>
            <p>MLOps solutions to manage, deploy, and scale your machine learning models with performance, scalability, and security in mind.</p>
          </div>
          <div className="service">
            <h3>Data-Driven Insights</h3>
            <p>Transform your data into actionable insights with our advanced analytics and AI capabilities, ensuring you stay ahead of the competition.</p>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="customers" className="content-section">
        <h2>Our Customers</h2>
        <div className="customers">
          <div className="customer">
            <a href="https://nettbil.no" alt="Nettbil link">
              <img src={nettbil} alt="Nettbil" className="customer-img" />
            </a>
            <p>Flow Lab has been instrumental in the development of application platform and in initiating AI/ML efforts. The first models, including price prediction, have significantly boosted sales conversion.</p>
          </div>
          <div className="customer">
            <a href="https://biosort.no" alt="Biosort link">
              <img src={biosort} alt="Biosort" className="customer-img" />
            </a>
            <p>Flow Lab played a crucial role in developing an efficient data pipeline for transferring terabytes of images from edge systems to on-premises data servers. They also implemented an annotation tool for the data used to train computer vision models.</p>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="content-section">
        <Contact />
      </section>

      {/* Footer Section */}
      <footer className="footer">
        <p>Follow us on <a href="https://www.linkedin.com/company/flowlab-no">LinkedIn</a></p>
        <p>&copy; 2024 Flow Lab | Oslo, Norway</p>
      </footer>
    </div>
  );
};

class Contact extends Component {
  render() {
    return <div className="address">
      <h2>Contact Us</h2>
      <p>Email: <a href="mailto:kontakt@flowlab.no">kontakt@flowlab.no</a> | Phone: +47 45064756</p>
      <p>Flow Lab AS - Org. nr. 917 482 209<br />Rebel, Universitetsgata 2, 0164 Oslo</p>
    </div>
  }
}

export default App;
